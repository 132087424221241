@mixin backlight($x, $y, $spread, $size, $colorA, $colorB, $colorC, $duration) {
    &:after {
        position: absolute;
        content: "";
        top: $y;
        left: $x;
        right: 0;
        z-index: -1;
        height: 100%;
        width: 100%;
        margin: 0 auto;
        transform: scale($size);
        -webkit-filter: blur($spread);
        -moz-filter: blur($spread);
        -ms-filter: blur($spread);
        filter: blur($spread);
        background: linear-gradient(270deg, $colorA, $colorB, $colorC);
        background-size: 200% 200%;
        animation: animateGlow $duration ease infinite;
        
        @keyframes animateGlow {
            0%{background-position:0% 50%}
            50%{background-position:100% 50%}
            100%{background-position:0% 50%}
        }
    }
}

.cards{
    padding: 50px;
    min-height: 150px;
    background: $white;
    border: 1px solid rgba(255,255,255, 0.8);
    border-radius: 24px;
    position: relative;

    a:hover{
        & ~ button{
            .circle {
            width: 100%;
                .icon {
                    &.arrow {
                    background: $dark;
                    transform: translate(1rem, 0);
                    }
                }
            }
            .button-text {
            color: $dark;
            opacity: 1;
            }
        }
    }

    h3{
        font-size: 2em;
        font-family: 'Suez One', serif;
        font-weight: 500;
        line-height: 1em;
        margin-bottom: 32px;
    }

    &-medium{


        h3{
            font-size: 1em;
        }
    }

    &.linkedin{
        background: #0a5bb2;
        color: #FFF;
    }

    &.twitch{
        background: #EAE1FF;
        color: #8419FF;

        #OnAir{
            position: absolute;
            padding: 18px;
            border-radius: 12px;
            background: $white;
            top: 50px;
            right: 50px;
            min-width: 100px;
            z-index: 0;

            &.hidden{
                visibility: hidden;
            }

            &.live{

                span.live-icon {
                    display: inline-block;
                    background-color: #FF2400;
                    width: 10px;
                    height: 10px;
                    margin-right: 10px;
                    border: .5px solid rgba(255, 255, 255, 0.8);
                    border-radius: 50%;
                    z-index: 10;

                    &:before {
                      content: "";
                      display: block;
                      position: absolute;
                      background-color: #FF2400;
                      width: 10px;
                      height: 10px;
                      border-radius: 50%;
                      -webkit-animation: live 1.5s ease-in-out 0s infinite;
                      animation: live 1.5s ease-in-out 0s infinite;
                      z-index: -1;
                      top: 18px;
                      left: 18px;
                    }
                }

                @keyframes live {
                    0% {
                    transform: scale(0, 0);
                    }
                    100% {
                    transform: scale(2.5, 2.5);
                    background-color: rgba(255, 255, 255, 0);
                    }
                }

                p{
                    color: #FF2400;
                }
            }

            span.live-icon{
                display: inline-block;
                background-color: #dbdbdb;
                width: 10px;
                height: 10px;
                margin-right: 10px;
                border: .5px solid rgba(255, 255, 255, 0.8);
                border-radius: 50%;
                z-index: 10;
            }


            p{
                display: inline;
                top: 48%;
                position: absolute;
                transform: translateY(-50%);
                font-weight: 800;
                color: #dbdbdb;
            }
        }

        &:before{
            content: '';
            background-image: url('/assets/images/twitch.svg');
            width: 50px;
            height: 50px;
            display: inline-block;
            margin-bottom: 40px;
        }

    }

    &.dribbble{
        border: #fff solid 2px;
        background: rgb(248,213,208);
        background: -moz-linear-gradient(72deg, rgba(248,213,208,1) 0%, rgb(209, 69, 191) 100%);
        background: -webkit-linear-gradient(72deg, rgba(248,213,208,1) 0%, rgb(209, 69, 191) 100%);
        background: linear-gradient(72deg, rgba(248,213,208,1) 0%, rgb(209, 69, 191) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f8d5d0",endColorstr="#f5c9ef",GradientType=1); 
        @include backlight(0, 0, 55vw, 0.95, rgba(248,213,208,1), rgb(209, 69, 191), rgb(12, 12, 240), 5s);
        
        .logo{
            margin-right: 32px;
            
            img{
                max-width: 54px;
                transition: transform .7s ease-in-out;
            }
        }

        &:hover{
            
            .logo{
                img{
                    transform: rotate(360deg);
                }
            }
        }

        .content{
            h3{
                font-size: 2em;
                font-family: 'Suez One', serif;
                font-weight: 500;
                line-height: 1em;
                margin-bottom: 32px;
            }

            button.btn-custom{
                padding: 12px 24px;
                background: #fff;
                color: #263238;
                border: solid 1px rgba(38, 50, 56, .2);
                font-weight: 700;
                transition: all .35s;

                &:hover{
                    background: #263238;
                    color: #fff;
                }
            }







        }
    }

    &-small{
        padding: 30px;
        background: #1f2937;
        border: 1px solid #374151;
        position: relative;
        display: flex;
        margin-bottom: 35px;

        .logo{
            max-width: 100%;
            width: 60px;
            height: 60px;
            background-color: $white;
            padding: 5px;
            border: 1px solid #374151;
            border-radius: 50%;
            position: relative;

            img{
                width: 45px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
            }
        }
        .content{
            box-sizing: border-box;
            min-width: 0;
            margin-left: 16px;
            line-height: 1em;

            h4{
                font-weight: 700;
                font-size: 1.2em;
            }

            p:not(:last-child){
                margin-bottom: 0.5em;
            }
        }

    }

    button {
        position: relative;
        display: inline-block;
        cursor: pointer;
        outline: none;
        border: 0;
        vertical-align: middle;
        text-decoration: none;
        background: transparent;
        padding: 0;
        margin-top: 30px;
        z-index: 10;

        &.learn-more {
            width: 15rem;
            height: auto;

            .circle {
                @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
                position: relative;
                display: block;
                margin: 0;
                width: 3rem;
                height: 3rem;
                background: $white;
                border-radius: 1.625rem;
                box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
                .icon {
                    @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    background: $dark;

                    &.arrow {
                        @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
                        left: 0.625rem;
                        width: 1.125rem;
                        height: 0.125rem;
                        background: none;

                        &::before {
                            position: absolute;
                            content: '';
                            top: -0.23rem;
                            right: 0.0625rem;
                            width: 0.625rem;
                            height: 0.625rem;
                            border-top: 0.125rem solid $dark;
                            border-right: 0.125rem solid $dark;
                            transform: rotate(45deg);
                        }
                    }
                }
            }
            .button-text {
                @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                padding: 0.55rem 0;
                margin: 0 0 0 1rem;
                color: $black;
                font-family: 'Suez One', serif;
                font-weight: 500;
                line-height: 1.6;
                text-align: center;
                opacity: 0;
            }
        }
    }

    &:hover{
        box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
        transition: box-shadow 0.3s ease-in-out;
    }
}
