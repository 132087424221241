header{
    padding: 0;
    margin: 10em 0 5em 0;
    position: relative;

    h1{
        font-size: 1.3em;
        font-family: 'Inter', sans-serif;
        font-weight: 700;
        line-height: 1em;
        margin-bottom: 1.2em;

        .wave {
            font-size: 42px;
            margin-right: 18px;
            animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
            animation-duration: 2.5s;        /* Change to speed up or slow down */
            animation-iteration-count: infinite;  /* Never stop waving :) */
            transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
            display: inline-block;
        }
        @keyframes wave-animation {
            0% { transform: rotate( 0.0deg) }
           10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
           20% { transform: rotate(-8.0deg) }
           30% { transform: rotate(14.0deg) }
           40% { transform: rotate(-4.0deg) }
           50% { transform: rotate(10.0deg) }
           60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
          100% { transform: rotate( 0.0deg) }
        }
    }
    .second{
        margin: 0 -24px;
        padding: 24px;
        border-radius: 18px;
        background: transparent;
        transition: background .2s ease-in-out;




        &:hover{
            background: #374151;
            .word{
                text-decoration: none;
            }
        }
        .word{
            text-decoration: underline;
        }
    }
}

.mindblow{
    cursor: url('/assets/images/mindblow.gif'), pointer;
}
