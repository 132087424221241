.anim{
  transition: all 750ms ease-in-out;
}
.contact{
    position: fixed;
    top: 2em;
    left: 2em;
    z-index: 1188;
    
    .btn-contact{
        -webkit-appearance: none;
        background: -webkit-gradient(to right,$green 0%,$sand 50%,$peach 100%);
        background: linear-gradient(to right,$green 0%,$sand 50%,$peach 100%);
        background-size: 500%;
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
        color: #263238;
        cursor: pointer;
        outline: none;
        -webkit-tap-highlight-color: transparent;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        padding: 12px 18px;
        font-weight: 700;
        border: 2px solid #263238;

        &:hover{
            animation-name: gradient;
            -webkit-animation-name: gradient;
            animation-duration: 2s;
            -webkit-animation-duration: s;
            animation-iteration-count: 1;
            -webkit-animation-iteration-count: 1;
            animation-fill-mode: forwards;
            -webkit-animation-fill-mode: forwards;

    @keyframes gradient{
            0% {
                background-position: 0% 50%;
            }
            100%{
                background-position: 100% ;
            }
        }

        }

        span{
            margin-right: 12px;
        }
    }
}

// #contact{
//     position: fixed;
//     width: 180px;
//     height: 180px;
//     margin: 0 auto;
//     backface-visibility: hidden;
//     top: 0;
//     left: 0;
//     z-index: 1188;

//     &:hover{
//         .sticky{
//             transform: rotate(10deg);
//         }

//         .front{
//             height: 70px;
//             -webkit-box-shadow: 0 -60px 10px -60px rgba(0,0,0,.1);

//             .circle{
//                 margin-top: -90px;
//                 background-color: #293949;
//                 background-position: 0 100px;

//             }
//         }

//         .back{
//             height: 90px;
//             top: 110px;

//             .circle{
//                 margin-top: -50px;
//             }
//         }

//         h4{
//             opacity: 0;
//             transition: opacity 50ms linear 300ms;
//         }


//     }
//     .circle_wrapper{
//         position: absolute;
//         width: 180px;
//         height: 180px;
//         left: 0px;
//         top: 0px;
//         overflow: hidden;

//     }

//     h4{
//         font-size: 2em;
//         font-weight: 200;
//         text-align: center;
//         position: absolute;
//         top: 50%;
//         left: 50%;
//         transform: translate(-50%,-50%);
//         transition: opacity 50ms linear 400ms;
//     }

//     .sticky{
//         position: absolute;
//         top: 0;
//         left: 0;
//         width:180px;
//         height: 180px;
//         transform: rotate(45deg);
//     }

//     .circle{
//         position: absolute;
//         width: 140px;
//         height: 140px;
//         margin: 20px;
//         margin-top: 20px;
//         border-radius: 999px;
//     }

//     .front{
//         height: 150px;
//         bottom: 0;
//         top: auto;
//         -webkit-box-shadow: 0 -140px 20px -140px rgba(0,0,0,.3);

//         .circle{
//             margin-top: -90px;
//             background-color: #364559;
//             background-position: 0 100px;
//             margin-top: -10px;
//             background: #364559;
//             background-image: -webkit-linear-gradient(bottom, rgba(41,57,73,.0) 75%, #233240 95%);
//             background-image: -moz-linear-gradient(bottom, rgba(41,57,73,.0) 75%, #233240 95%);
//             background-image: linear-gradient(bottom, rgba(41,57,73,.0) 75%, #233240 95%);
//         }
//     }
//     .back{
//         height: 10px;
//         top: 30px;

//         .circle{
//             margin-top: -130px;
//             background-color: #364559;
//             background-image: -webkit-linear-gradient(bottom, rgba(251,236,63,.0), rgba(255,255,255,.8));
//         }
//     }

//     .reveal{

//         .circle{
//             background: #fafafa;
//             box-shadow: 0 1px 0px rgba(0,0,0,.15);
//             width: 140px;
//             height: 140px;
//             text-align: center;
//             cursor: pointer;

//             span{
//                 position: absolute;
//                 top: 40%;
//                 left: 50%;
//                 transform: translate(-50%,-40%);
//                 line-height: 0.5em;
//                 font-weight: 700;
//                 width: 100%;
//                 font-size: 0.8em;
//             }
//         }
//     }
// }
