::selection{
    background: #333;
    color: $white;
}

body {
    font-family: 'Inter', sans-serif;
    font-size: 1.2em;
    background: #1d212b;
    color: $white;
    line-height: 1.8em;

}

p{
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-style: normal;

}

.bg-link{
    border: none;
    display: block;
    cursor: pointer;
    z-index: 100;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

section.dribbble{
    margin-bottom: 5em;
}