@media (min-width: 960px) {
    .container{
        width: 820px;
    }
}


// VARIABLES
$white: #fff;
$dark: #263238;

$green: #a2ccb6;
$light-peach: #ffecd9;
$peach: #ee786e;
$sand: #fceeb5;


// TRANSITIONS
@mixin transition($property: all, $duration: 0.45s, $ease: cubic-bezier(0.65,0,.076,1)) {
  transition: $property $duration $ease;
}
