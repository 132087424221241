@font-face {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-style: bold;
}
@font-face {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-style: regular;
}
