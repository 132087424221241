.footer {
    padding: 40px 0 0 0;
    border-top: 0.5px solid #f2f2f2;
    margin: 40px 0;
    font-size: 12px;

    a{
        display: inline-block;
    }
}
